<template>
  <v-container fluid>
    <v-row class="d-flex justify-center">
      <v-col md="12" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Task Details</div>
        </div>
      </v-col>
      <v-col md="6" cols="12">
        <div class="pt-3">
          <div class="salesLable pt-3">Project Name :</div>
        </div>
        <div class="pt-3">
          <div class="salesLable pt-3">Sales Name :</div>
        </div>
        <div class="pt-3">
          <div class="salesLable pt-3">Client Name :</div>
        </div>
        <div class="pt-3">
          <div class="salesLable pt-3">
            Task Describtion :<span class="details">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem
              ex tenetur consectetur cum natus, obcaecati dolore fugiat optio
              molestiae deleniti architecto saepe ipsa! Ex fuga esse fugit
              nostrum ipsa nobis!</span
            >
          </div>
        </div>
        <div class="pt-3">
          <div class="salesLable pt-3">Total Hours :</div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();

export default {
  data: () => ({
    projectList: [],
    tasksList: [],
    pagination: {
      current_page: 1,
    },

    project_id: "",
    task_id: "",
  }),
  computed: {},
  methods: {
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];
      this.Team = {};
      apiServices
        .get(
          `view/project/view_attendance?per_page=7&page=${page}&project_id=${this.$route.params.project_id}`
        )
        .then((res) => {
          if (res) {
            this.isLoadingSearch = false;
            this.listData = res.data;

            this.pagination = res.meta;
            // console.log("team", this.Team);
            console.log("ListData", this.listData);
            console.log("pagination", this.pagination);

            this.isLoading = false;
          } else this.isLoading = false;
        });
    },
  },
  created() {
    this.getListData(this.pagination.current_page);
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.salesLable {
  padding: 10px;
  border: 2px solid black;
  font-size: 18px;
  background-color: whitesmoke;
  border-radius: 5px;
  font-weight: 700;
}
.details {
  font-size: 18px;
  color: #776969;
  font-weight: 200;
}
</style>
